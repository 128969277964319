import React, { forwardRef, useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import Lozenge from '@atlaskit/lozenge';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { Flex } from '@atlaskit/primitives';
import InformationIcon from '@atlaskit/icon/core/information';
import { token } from '@atlaskit/tokens';

import { SPAViewContext } from '@confluence/spa-view-context';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';
import { fg } from '@confluence/feature-gating';

export const i18n = defineMessages({
	publishedPill: {
		id: 'company-hub-utils.hub-status-lozenge.general.dashboard.pill.published',
		defaultMessage: 'Published',
		description: 'Pill that informs the user if their hub is in a published state',
	},
	publishedTooltip: {
		id: 'company-hub-utils.hub-status-lozenge.general.dashboard.tooltip.published',
		defaultMessage: 'Your hub is visible to everyone in the company',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedPill: {
		id: 'company-hub-utils.hub-status-lozenge.general.dashboard.pill.not-published',
		defaultMessage: 'Not published',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedTooltip: {
		id: 'company-hub-utils.hub-status-lozenge.general.dashboard.tooltip.not-published',
		defaultMessage:
			'Your hub is only visible to you and other admin and editors. The rest of the company will not see your hub until you publish.',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
});

const tooltipStyles = css({
	background: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	boxShadow: token('elevation.shadow.overlay'),
	color: token('color.text'),
	padding: `${token('space.100')} ${token('space.150')}`,
	maxWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
export const customTooltipContent = forwardRef<HTMLDivElement, TooltipPrimitiveProps>(
	function customTooltipContent({ children, ...props }, ref) {
		return (
			<TooltipPrimitive {...props} ref={ref} css={tooltipStyles}>
				{children}
			</TooltipPrimitive>
		);
	},
) as React.ForwardRefExoticComponent<TooltipPrimitiveProps>;

export const HubStatusLozenge = () => {
	const { formatMessage } = useIntl();
	const { frontCoverState } = useContext(SPAViewContext);
	const isPublished = frontCoverState === FrontCoverStateEnum.SHOWN;

	return (
		<Flex gap="space.100" alignItems="center">
			<Lozenge
				appearance={isPublished ? 'success' : undefined}
				isBold={fg('company_hub_creation_ui')}
			>
				<FormattedMessage {...(isPublished ? i18n.publishedPill : i18n.notPublishedPill)} />
			</Lozenge>
			<Tooltip
				component={customTooltipContent}
				position="bottom-start"
				content={formatMessage(isPublished ? i18n.publishedTooltip : i18n.notPublishedTooltip)}
			>
				<InformationIcon label="" color={token('color.icon')} />
			</Tooltip>
		</Flex>
	);
};
