import React, { useCallback, useState, useEffect } from 'react';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { scrollCommentIntoView } from '@confluence/comments-util';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { useInlineCommentsState } from '@confluence/inline-comments-hooks';
import {
	useInlineCommentsContext,
	useInlineCommentsDispatchContext,
	useCommentsContentState,
	useCommentsContentActions,
} from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	GeneralShortcutListener,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
	UNIVERSAL_NEXT_UNREAD_COMMENT_SHORTCUT,
	UNIVERSAL_NEXT_COMMENT_SHORTCUT,
	UNIVERSAL_PREV_COMMENT_SHORTCUT,
} from '@confluence/shortcuts';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { handlePanelSelectionAndScroll } from '@confluence/comments-panel';
import { useCommentsPanel, useCommentsPanelScroll } from '@confluence/comments-panel-utils';
import { useObjectSidebarState, PanelName } from '@confluence/object-sidebar-api';
import { fg } from '@confluence/feature-gating';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useUnreadCommentsState } from '@confluence/unread-comments';

import { getNextRef, parsePanelAndInlineRefs } from './helper/commentsShortcutsHelper';

type ViewCommentShortcutListenerProps = {
	isFabricPage: boolean;
	contentId?: string;
};

const ViewCommentShortcutListenerComponent = ({
	isFabricPage,
	contentId,
}: ViewCommentShortcutListenerProps) => {
	const { navigateToInlineComment } = useInlineCommentsDispatchContext();
	const { activeHighlight } = useInlineCommentsContext();
	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const { removeCommentQueryParams } = useInlineCommentQueryParams();
	const { unresolvedInlineComments } = useInlineCommentsState();
	const emitter = getRendererAnnotationEventEmitter();
	const { showModal } = useDialogs();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isNextCommentLoading, setIsNextCommentLoading] = useState(false);
	const [
		{ currentlyRenderedThreads, currentlySelectedCommentMarkerRef },
		{ setCurrentlySelectedCommentMarkerRef },
	] = useCommentsPanel();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();
	const { unreadCommentsListState } = useUnreadCommentsState();

	const { isObjectSidebarShown, panel } = useObjectSidebarState();
	const pageMode = useGetPageMode();

	useEffect(() => {
		setIsNextCommentLoading(false);
	}, [activeHighlight]);

	const getCommentIndex = useCallback(
		(currentMarkerRef: string): number => {
			return unresolvedInlineComments?.findIndex((markerRef) => markerRef === currentMarkerRef);
		},
		[unresolvedInlineComments],
	);

	const openInlineComment = useCallback(
		(markerRef: string) => {
			const commentElement = document.getElementById(markerRef);
			emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
				annotationIds: [markerRef],
				eventTarget: commentElement,
			});
			scrollCommentIntoView({ commentElement, isFabricPage: true });
		},
		[emitter],
	);

	const onNavigationClick = useCallback(
		(nextMarkerRef: string) => {
			if (hasContentChanged) {
				showModal(CommentWarningDialog, {
					onConfirm: () => {
						resetContentChanged();
						openInlineComment(nextMarkerRef);
					},
				});
			} else {
				openInlineComment(nextMarkerRef);
			}
		},
		[hasContentChanged, resetContentChanged, showModal, openInlineComment],
	);

	const handlePanelCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// If nothing is on the panel, there's nothing to navigate to
			if (currentlyRenderedThreads.length === 0) {
				return;
			}

			const { panelCommentRefs, navigableInlineCommentRefs } = parsePanelAndInlineRefs(
				currentlyRenderedThreads,
				unresolvedInlineComments,
			);

			const nextRef = getNextRef(action, panelCommentRefs, currentlySelectedCommentMarkerRef);

			// Equivalent to clicking on the comment in the panel
			handlePanelSelectionAndScroll({
				threadKey: nextRef.ref,
				isResolved: !navigableInlineCommentRefs.has(nextRef.ref),
				pageMode,
				setCurrentlySelectedCommentMarkerRef,
				eventEmitter: emitter,
				commentType: nextRef.type,
				scrollCommentsPanelToThread,
				isKeyboardShortcutTriggered: true,
			});
		},
		[
			currentlyRenderedThreads,
			unresolvedInlineComments,
			currentlySelectedCommentMarkerRef,
			pageMode,
			setCurrentlySelectedCommentMarkerRef,
			emitter,
			scrollCommentsPanelToThread,
		],
	);

	const handleNextCommentNavigation = useCallback(
		({
			action,
			unreadInlineComments,
			isNavigateToNextUnread,
		}: {
			unreadInlineComments?: string[];
			isNavigateToNextUnread: boolean;
			action: 'next' | 'previous';
		}) => {
			//Check if the next inline comment is still in process of being rendered. Otherwise, quickly pressing keys will force comment to open and close instead of navigating
			if (isNextCommentLoading) return;

			setIsNextCommentLoading(true);

			navigateToInlineComment({
				action,
				triggeredByKeyboardShortcut: true,
				currentCommentIndex: getCommentIndex(activeHighlight || ''),
				onNavigationClick,
				isEditor: false,
				removeCommentQueryParams,
				unresolvedInlineComments,
				createAnalyticsEvent,
				contentId,
				isNavigateToNextUnread,
				unreadInlineComments,
			});
		},
		[
			getCommentIndex,
			isNextCommentLoading,
			activeHighlight,
			navigateToInlineComment,
			onNavigationClick,
			removeCommentQueryParams,
			unresolvedInlineComments,
			createAnalyticsEvent,
			contentId,
		],
	);

	const handleInlineCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			if (activeHighlight) {
				handleNextCommentNavigation({
					isNavigateToNextUnread: false,
					action,
				});
			} else {
				// No highlight, so we navigate to the first inline comment
				const annotations: HTMLElement[] = [].slice.call(
					document.querySelectorAll(`[data-mark-annotation-type="inlineComment"]`),
				);
				const firstInlineCommentMarkerRef = annotations.find(
					(item) => item.getAttribute('data-mark-annotation-state') === 'active', //active means it's open (i.e. not resolved)
				)?.id;

				if (!firstInlineCommentMarkerRef) {
					// No active inline comments found, nothing to do
					return;
				}

				openInlineComment(firstInlineCommentMarkerRef || '');
			}
		},
		[activeHighlight, openInlineComment, handleNextCommentNavigation],
	);

	const handleCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// Define behavior when the comments panel is shown - panel FG is a prereq for nav, so has to be checked first
			if (
				// eslint-disable-next-line confluence-feature-gating/no-preconditioning
				fg('confluence-frontend-comments-panel') &&
				isObjectSidebarShown &&
				panel?.id === PanelName.CommentsPanel
			) {
				handlePanelCommentNavigation(action);
			} else {
				// Comments panel not shown
				handleInlineCommentNavigation(action);
			}
		},
		[isObjectSidebarShown, panel?.id, handlePanelCommentNavigation, handleInlineCommentNavigation],
	);

	const handleNextUnreadNavigation = useCallback(() => {
		if (
			!unreadCommentsListState.length ||
			// do not handle the case when comments panel isn't open for now
			// eslint-disable-next-line confluence-feature-gating/no-preconditioning
			(fg('confluence-frontend-comments-panel') &&
				isObjectSidebarShown &&
				panel?.id === PanelName.CommentsPanel)
		) {
			// No unread comments - don't do anything
			return;
		}

		const unreadInlineComments = unreadCommentsListState.map((item) => item.threadKey || '');
		if (activeHighlight) {
			handleNextCommentNavigation({
				unreadInlineComments,
				isNavigateToNextUnread: true,
				action: 'next',
			});
		} else {
			openInlineComment(unreadInlineComments[0] || '');
		}
	}, [
		isObjectSidebarShown,
		panel?.id,
		handleNextCommentNavigation,
		unreadCommentsListState,
		activeHighlight,
		openInlineComment,
	]);

	if (!isFabricPage) return null;

	return (
		<div data-testId="comments-shortcut-listener">
			<GeneralShortcutListener
				accelerator={NEXT_COMMENT_SHORTCUT} //This will eventually be replaced with universal shortcuts
				listener={() => handleCommentNavigation('next')}
			/>
			<GeneralShortcutListener
				accelerator={PREV_COMMENT_SHORTCUT} //This will eventually be replaced with universal shortcuts
				listener={() => handleCommentNavigation('previous')}
			/>
			<div data-testid="universal-comments-shortcut-listener">
				<GeneralShortcutListener
					accelerator={UNIVERSAL_NEXT_COMMENT_SHORTCUT}
					listener={() => handleCommentNavigation('next')}
				/>
				<GeneralShortcutListener
					accelerator={UNIVERSAL_PREV_COMMENT_SHORTCUT}
					listener={() => handleCommentNavigation('previous')}
				/>
			</div>
			{fg('confluence_frontend_comments_universal_shortcuts') && (
				<GeneralShortcutListener
					accelerator={UNIVERSAL_NEXT_UNREAD_COMMENT_SHORTCUT}
					listener={handleNextUnreadNavigation}
				/>
			)}
		</div>
	);
};

export const ViewCommentShortcutListener = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(ViewCommentShortcutListenerComponent);
