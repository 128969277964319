import React, { useCallback, useState, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useUnreadCommentsState } from '@confluence/unread-comments';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { getEditorAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { openInlineCommentInEditor } from '@confluence/comments-util';
import { useInlineCommentsState } from '@confluence/inline-comments-hooks';
import {
	useInlineCommentsContext,
	useInlineCommentsDispatchContext,
} from '@confluence/comment-context';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	GeneralShortcutListener,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
	UNIVERSAL_PREV_COMMENT_SHORTCUT,
	UNIVERSAL_NEXT_COMMENT_SHORTCUT,
	UNIVERSAL_NEXT_UNREAD_COMMENT_SHORTCUT,
} from '@confluence/shortcuts';
import { handlePanelSelectionAndScroll } from '@confluence/comments-panel';
import { useCommentsPanel, useCommentsPanelScroll } from '@confluence/comments-panel-utils';
import { useObjectSidebarState, PanelName } from '@confluence/object-sidebar-api';
import { fg } from '@confluence/feature-gating';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';

import { parsePanelAndInlineRefs, getNextRef } from './helper/commentsShortcutsHelper';

const EditorCommentShortcutListenerComponent = ({
	contentId,
	viewMode,
}: {
	contentId?: string;
	viewMode?: boolean;
}) => {
	const { navigateToInlineComment } = useInlineCommentsDispatchContext();
	const { unresolvedInlineComments } = useInlineCommentsState();
	const { removeCommentQueryParams } = useInlineCommentQueryParams();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { activeHighlight } = useInlineCommentsContext();
	const [isNextCommentLoading, setIsNextCommentLoading] = useState(false);
	const [
		{ currentlyRenderedThreads, currentlySelectedCommentMarkerRef },
		{ setCurrentlySelectedCommentMarkerRef },
	] = useCommentsPanel();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();
	const { isObjectSidebarShown, panel } = useObjectSidebarState();
	const pageMode = useGetPageMode();
	const emitter = getEditorAnnotationEventEmitter();
	const { unreadCommentsListState } = useUnreadCommentsState();

	useEffect(() => {
		setIsNextCommentLoading(false);
	}, [activeHighlight]);

	const onNavigationClick = useCallback(
		(nextMarkerRef?: string) => {
			if (!nextMarkerRef) return;

			emitter.emit('setselectedannotation', nextMarkerRef);
			const commentElement = document.getElementById(nextMarkerRef);
			if (commentElement) {
				openInlineCommentInEditor(commentElement, nextMarkerRef);
			}
		},
		[emitter],
	);

	const getCommentIndex = useCallback(
		(currentMarkerRef: string): number => {
			return unresolvedInlineComments?.findIndex((markerRef) => markerRef === currentMarkerRef);
		},
		[unresolvedInlineComments],
	);

	const handlePanelCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// If nothing is on the panel, there's nothing to navigate to
			if (currentlyRenderedThreads.length === 0) {
				return;
			}

			const { panelCommentRefs, navigableInlineCommentRefs } = parsePanelAndInlineRefs(
				currentlyRenderedThreads,
				unresolvedInlineComments,
			);

			const nextRef = getNextRef(action, panelCommentRefs, currentlySelectedCommentMarkerRef);

			// Equivalent to clicking on the comment in the panel
			handlePanelSelectionAndScroll({
				threadKey: nextRef.ref,
				isResolved: !navigableInlineCommentRefs.has(nextRef.ref),
				pageMode,
				setCurrentlySelectedCommentMarkerRef,
				eventEmitter: emitter,
				commentType: nextRef.type,
				scrollCommentsPanelToThread,
				isKeyboardShortcutTriggered: true,
			});
		},
		[
			currentlyRenderedThreads,
			unresolvedInlineComments,
			currentlySelectedCommentMarkerRef,
			pageMode,
			setCurrentlySelectedCommentMarkerRef,
			emitter,
			scrollCommentsPanelToThread,
		],
	);

	const handleNextCommentNavigation = useCallback(
		({
			unreadInlineComments,
			isNavigateToNextUnread,
			action,
		}: {
			unreadInlineComments?: string[];
			isNavigateToNextUnread: boolean;
			action: 'next' | 'previous';
		}) => {
			//Check if the next inline comment is still in process of being rendered. Otherwise, quickly pressing keys will force comment to open and close instead of navigating
			if (isNextCommentLoading) return;

			setIsNextCommentLoading(true);

			navigateToInlineComment({
				action,
				triggeredByKeyboardShortcut: true,
				currentCommentIndex: getCommentIndex(activeHighlight || ''),
				onNavigationClick,
				isEditor: true,
				removeCommentQueryParams,
				unresolvedInlineComments,
				createAnalyticsEvent,
				contentId,
				isNavigateToNextUnread,
				unreadInlineComments,
			});
		},
		[
			activeHighlight,
			navigateToInlineComment,
			onNavigationClick,
			removeCommentQueryParams,
			unresolvedInlineComments,
			createAnalyticsEvent,
			contentId,
			getCommentIndex,
			isNextCommentLoading,
		],
	);

	const handleInlineCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			const firstInlineCommentMarkerRef = document
				.querySelector('.ak-editor-annotation-blur')
				?.closest('[annotationtype="inlineComment"]')?.id;

			if (activeHighlight) {
				handleNextCommentNavigation({
					isNavigateToNextUnread: false,
					action,
				});
			} else {
				onNavigationClick(firstInlineCommentMarkerRef);
			}
		},
		[activeHighlight, onNavigationClick, handleNextCommentNavigation],
	);

	const handleCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// Define behavior when the comments panel is shown - panel FG is a prereq for nav, so has to be checked first
			if (
				// eslint-disable-next-line confluence-feature-gating/no-preconditioning
				fg('confluence-frontend-comments-panel') &&
				isObjectSidebarShown &&
				panel?.id === PanelName.CommentsPanel
			) {
				handlePanelCommentNavigation(action);
			} else {
				// Comments panel not shown
				handleInlineCommentNavigation(action);
			}
		},
		[isObjectSidebarShown, panel?.id, handlePanelCommentNavigation, handleInlineCommentNavigation],
	);

	const handleNextUnreadNavigation = useCallback(() => {
		if (
			!unreadCommentsListState.length ||
			// do not handle the case when comments panel isn't open for now
			// eslint-disable-next-line confluence-feature-gating/no-preconditioning
			(fg('confluence-frontend-comments-panel') &&
				isObjectSidebarShown &&
				panel?.id === PanelName.CommentsPanel)
		) {
			// No unread comments - don't do anything
			return;
		}

		const unreadInlineComments = unreadCommentsListState.map((item) => item.threadKey || '');
		if (activeHighlight) {
			handleNextCommentNavigation({
				unreadInlineComments,
				isNavigateToNextUnread: true,
				action: 'next',
			});
		} else {
			onNavigationClick(unreadInlineComments[0] || '');
		}
	}, [
		isObjectSidebarShown,
		panel?.id,
		handleNextCommentNavigation,
		unreadCommentsListState,
		onNavigationClick,
		activeHighlight,
	]);

	return (
		<div data-testId="comments-shortcut-listener">
			<GeneralShortcutListener
				accelerator={viewMode ? PREV_COMMENT_SHORTCUT : UNIVERSAL_PREV_COMMENT_SHORTCUT}
				listener={() => handleCommentNavigation('previous')}
			/>
			<GeneralShortcutListener
				accelerator={viewMode ? NEXT_COMMENT_SHORTCUT : UNIVERSAL_NEXT_COMMENT_SHORTCUT}
				listener={() => handleCommentNavigation('next')}
			/>
			{viewMode && (
				<div data-testid="universal-comments-shortcut-listener">
					<GeneralShortcutListener
						accelerator={UNIVERSAL_PREV_COMMENT_SHORTCUT}
						listener={() => handleCommentNavigation('previous')}
					/>
					<GeneralShortcutListener
						accelerator={UNIVERSAL_NEXT_COMMENT_SHORTCUT}
						listener={() => handleCommentNavigation('next')}
					/>
				</div>
			)}
			{fg('confluence_frontend_comments_universal_shortcuts') && (
				<GeneralShortcutListener
					accelerator={UNIVERSAL_NEXT_UNREAD_COMMENT_SHORTCUT}
					listener={handleNextUnreadNavigation}
				/>
			)}
		</div>
	);
};

export const EditorCommentShortcutListener = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(EditorCommentShortcutListenerComponent);
