import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { Flex, xcss, Box } from '@atlaskit/primitives';

import {
	EXTENSION_POINT_SPACE_BUTTONS_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { ContentTools } from '@confluence/content-tools';
import { EditContentButton } from '@confluence/edit-button';
import { SPAViewContext } from '@confluence/spa-view-context';
import { WatchDialogWrapper, WATCH_DIALOG_LOCATION } from '@confluence/watch-dialog';
import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog';
import {
	INVITE_TO_EDIT_KEY,
	PAGE_FAVOURITE_KEY,
	PAGE_UNFAVOURITE_KEY,
	WATCH_BUTTON_KEY,
	EDIT_BUTTON_PAGE_KEY,
} from '@confluence/web-item';
import {
	PAGE_BUTTONS,
	WebItemLocation,
	type WebItemLocationChildrenFnWithLoading,
} from '@confluence/web-item-location';
import { WebItemButtonList } from '@confluence/web-item-list';
import { CommentButtonWrapper } from '@confluence/comment-button';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { CopyLinkButtonLoader } from '@confluence/share';
import { SpaceStarTextButton } from '@confluence/action-buttons/entry-points/spaceStar';
import { fg } from '@confluence/feature-gating';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';
import { ContentHeaderPresenceLoader } from '@confluence/team-presence/entry-points/ContentHeaderPresenceLoader';

import { SpaceHeader } from './SpaceHeader';
import { FavouriteSpaceQuery } from './FavouriteSpaceQuery.graphql';

// Exported only for testing
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpaceHeaderPlaceholder = styled.div({
	minHeight: '72px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisplayFlex = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWebItemButtonList = styled(WebItemButtonList)<typeof WebItemButtonList>({
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WebItemsAndSubstitutedWebItems = styled.div({
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.100'),
});

const NOT_ALLOWED_OVERVIEW_WEB_ITEMS = [
	PAGE_FAVOURITE_KEY,
	PAGE_UNFAVOURITE_KEY,
	WATCH_BUTTON_KEY,
	EDIT_BUTTON_PAGE_KEY,
];

const verticalSeparatorStyles = xcss({
	borderLeft: `2px solid ${token('color.border')}`,
	height: '16px',
	margin: 'space.075',
});

const VerticalSeparator = () => <Box xcss={verticalSeparatorStyles} />;

export interface SpaceOverviewGroupedButtonsProps {
	spaceKey: string;
	contentId: string;
	versionOverride?: number | null;
	spaceName: string;
	isThemed?: boolean;
	spaceIconPath?: string;
}

export const SpaceOverviewGroupedButtons: FC<SpaceOverviewGroupedButtonsProps> = ({
	spaceKey,
	contentId,
	versionOverride,
	spaceName,
	isThemed,
	spaceIconPath,
}) => {
	const { isAnonymous } = useContext(SPAViewContext);

	const { isExternalCollaborator } = useIsExternalCollaborator();
	const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible();

	const { data: favouriteStatusData } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FavouriteSpaceQuery,
		{
			variables: {
				spaceKey,
			},
		},
	);

	const isFavourited = favouriteStatusData?.space?.currentUser?.isFavourited;
	const spaceId = favouriteStatusData?.space?.id;

	return (
		<SpaceHeader
			spaceKey={spaceKey}
			isThemed={isThemed}
			spaceIconPath={spaceIconPath}
			contentId={contentId}
		>
			<DisplayFlex>
				{fg('confluence_team_presence_general_availability') && (
					<Flex alignItems="center">
						<ContentHeaderPresenceLoader
							contentId={contentId}
							contentType="page"
							analyticSource="viewPageScreen"
						/>
						<VerticalSeparator />
					</Flex>
				)}
				<EditContentButton contentId={contentId} />
				<CommentButtonWrapper key="comment-button" contentId={contentId} />
				<WatchDialogWrapper
					contentId={contentId}
					defaultTab={MANAGE_WATCHER_TAB_TYPES.SPACE}
					componentLocation={WATCH_DIALOG_LOCATION.SPACE_OVERVIEW}
				/>
				<ExperienceStart name={EXTENSION_POINT_SPACE_BUTTONS_EXPERIENCE} timeout={10000} />
				{!isExternalCollaborator && (
					<WebItemLocation
						key="page-web-items"
						tagName="span"
						contentId={contentId}
						location={PAGE_BUTTONS}
						notAllowedWebItems={NOT_ALLOWED_OVERVIEW_WEB_ITEMS}
						// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
						renderWhenLoading={process.env.REACT_SSR as any}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ display: 'flex' }}
					>
						{
							(({ webItems }) => {
								const foundShareWebItem = webItems.some(
									(item) => item.completeKey === INVITE_TO_EDIT_KEY,
								);

								const webItemsAfterSubstitution: typeof webItems = [];
								webItems.forEach((item) => {
									if (item.completeKey !== INVITE_TO_EDIT_KEY) {
										webItemsAfterSubstitution.push(item);
									} else if (spaceName) {
										// FIXME: such property modifications should not be performed on pre-existing items; a new array with copies should be created instead
										item.contentId = contentId;
										webItemsAfterSubstitution.push(item);
									}
								});

								return (
									<>
										{foundShareWebItem &&
											!(
												fg('confluence_frontend_unified_restrict_and_share') ||
												isUSDExperimentEnabled
											) && (
												<CopyLinkButtonLoader
													contentId={contentId}
													spaceKey={spaceKey}
													contentType="page"
													pageMode="view"
													source="spaceOverview"
												/>
											)}
										<WebItemsAndSubstitutedWebItems>
											<StyledWebItemButtonList
												pageId={contentId}
												webItems={webItemsAfterSubstitution}
												id="extracted-share-button"
											/>
											<ExperienceSuccess name={EXTENSION_POINT_SPACE_BUTTONS_EXPERIENCE} />
										</WebItemsAndSubstitutedWebItems>
									</>
								);
							}) as WebItemLocationChildrenFnWithLoading
						}
					</WebItemLocation>
				)}
				<ContentTools
					spaceKey={spaceKey}
					contentId={contentId}
					version={versionOverride}
					isExternalCollaborator={isExternalCollaborator}
				/>
				{isAnonymous ? null : (
					<SpaceStarTextButton
						spaceId={spaceId}
						spaceKey={spaceKey}
						isStarred={isFavourited || typeof isFavourited !== 'boolean'}
						analytics={{
							source: 'spaceOverview',
							attributes: {
								spaceId,
								isGuest: isExternalCollaborator,
							},
						}}
						dataTestId="space-star-text-button"
					/>
				)}
			</DisplayFlex>
		</SpaceHeader>
	);
};
