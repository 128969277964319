import type { CommentLocationGenericWithId } from '@confluence/comments-panel-queries';
import { CommentType } from '@confluence/comments-data';
type CommentRef = {
	ref: string;
	type: CommentType;
};

// Parses out which subset of the panel comments have an inline ref on the page (i.e. all comments except page comments) as panelInlineCommentRefs
// Also parses which subset of the inline comments are navigable (i.e. rendered inline on the page, so everything except resolved comments and page comments) as navigableInlineCommentRefs
export const parsePanelAndInlineRefs = (
	currentlyRenderedThreads: CommentLocationGenericWithId[],
	unresolvedInlineComments: string[],
) => {
	// Grab everything on the screen as a set for quick access
	const allRenderedInlineCommentIds: Set<string> = new Set(unresolvedInlineComments);

	// Grab all of the refs for inline comments that are available on the panel
	const panelCommentRefs: CommentRef[] = currentlyRenderedThreads
		.map((comment: CommentLocationGenericWithId) => {
			if (comment.type === 'INLINE') {
				if ('inlineMarkerRef' in comment && comment.inlineMarkerRef) {
					return { ref: comment.inlineMarkerRef, type: CommentType.INLINE };
				} else {
					return { ref: '', type: CommentType.INLINE };
				}
			} else {
				return { ref: comment.id, type: CommentType.GENERAL };
			}
		})
		.filter((comment: CommentRef) => {
			return comment.ref !== '';
		});

	// Grab the refs that we can navigate too (aka the ones rendered on the page, so open (i.e. not resolved) comments)
	const navigableInlineCommentRefs: Set<string> = new Set(
		panelCommentRefs.map((comment: CommentRef) => {
			if (comment.type === CommentType.INLINE) {
				return allRenderedInlineCommentIds.has(comment.ref) ? comment.ref : '';
			} else {
				return comment.ref;
			}
		}),
	);

	return { panelCommentRefs, navigableInlineCommentRefs };
};

export const getNextRef = (
	action: 'next' | 'previous',
	panelCommentRefs: CommentRef[],
	currentlySelectedCommentMarkerRef: string | undefined,
) => {
	let nextIndex = panelCommentRefs.findIndex((comment: CommentRef) => {
		return comment.ref === currentlySelectedCommentMarkerRef;
	});

	if (nextIndex === -1) {
		nextIndex = 0;
	} else {
		if (action === 'next') {
			nextIndex = (nextIndex + 1) % panelCommentRefs.length;
		} else {
			nextIndex = (nextIndex - 1 + panelCommentRefs.length) % panelCommentRefs.length;
		}
	}

	return panelCommentRefs[nextIndex];
};
