import { styled } from '@compiled/react';

import { fg } from '@confluence/feature-gating';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values
export const HubRendererWrapper = styled.div({
	// removing 96 gutter (akEditorBreakoutPadding) width by appling negative margin
	// this increases the width of extension by 96px
	// we have to adjust this value
	// in Editor we adds 40px in the same way
	// to compensate the difference we limit the width of extension to
	// 704px - default (800 - 96)
	// 955px - wide (1051 - 96)
	// 1744px - full width (1840 - 96)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div.ak-renderer-extension': {
		padding: '0 48px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div.ak-renderer-extension-overflow-container': {
		margin: '0 -48px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div.ak-renderer-extension[data-layout=default]': {
		maxWidth: '704px',
		// positioning extension with default layout in the center
		// in the same way as we do for wide and full-width extensions
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginLeft: '50%',
		transform: 'translateX(-50%)',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div.ak-renderer-extension[data-layout=wide]': {
		maxWidth: '955px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div.ak-renderer-extension[data-layout=full-width]': {
		maxWidth: '1744px',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& [data-extension-frame], & [data-layout-column]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& div.ak-renderer-extension': {
			paddingInline: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& div.ak-renderer-extension-overflow-container': {
			marginInline: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& div.ak-renderer-extension[data-layout=default]': {
			maxWidth: 'none',
		},
	},

	// This override is removing the marging between MBEs (Sections and any other future MBE extensions)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-multiBodiedExtension-container] + [data-multiBodiedExtension-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > .ak-renderer-extension': {
			marginTop: 0,
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
	...() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('company_hub_creation_ui_remove_margins_in_renderer') && {
			// Removing the margin between MBE and any extension
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'[data-multiBodiedExtension-container] + div.ak-renderer-extension': {
				marginTop: 0,
			},

			// Removing the margin between any extension and MBE
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'div.ak-renderer-extension + [data-multiBodiedExtension-container]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'& > .ak-renderer-extension': {
					marginTop: 0,
				},
			},

			// Removing the margin between any extensions
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'div.ak-renderer-extension + div.ak-renderer-extension': {
				marginTop: 0,
			},
		},
});
