import React from 'react';
import type { QueryResult } from 'react-apollo';

import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { DocumentSpaceTitle } from '@confluence/document-title';
import { EditContentButton } from '@confluence/edit-button';
import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';
import type {
	SpaceOverviewQueryType,
	SpaceOverviewQueryVariables,
} from '@confluence/space-overview-query';
import { HubStatusLozenge } from '@confluence/company-hub-utils/entry-points/HubStatusLozenge';

import {
	HubSettingsEntryButtonAppearance,
	HubSettingsEntryButton,
} from '../hub-settings/HubSettingsEntryButton';

const editButtonStylesOld = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '100%',
});

const editButtonStyles = xcss({
	position: 'relative',
	'::after': {
		content: '""',
		position: 'absolute',
		borderWidth: 'border.width',
		borderStyle: 'solid',
		borderRadius: 'border.radius.100',
		borderColor: 'color.border',
		inset: 'space.0',
		pointerEvents: 'none',
	},
});

// HubPreviewHeader should not overlay the content that follows it when the scroll position is at the top.
// Pulled here out of HubPreviewHeader to avoid vertical content shift given HubPreviewHeader loads lazily.
const fixedPreviewPlaceholderStyles = xcss({
	background: token('color.background.accent.gray.subtlest'), // Match BaseHubPreviewHeader
	height: `59px`, // BREADCRUMB_HEADER_HEIGHT like FixedSpaceHeaderContainer used by SpaceOverviewGroupedButtons, but lint doesn't allow using an import or a variable
});

const fixedPlaceholderStyles = xcss({
	height: '48px',
	containerType: 'inline-size',
});

const headerContainerStylesOld = xcss({
	justifyContent: 'space-between',
	padding: 'space.250',
	position: 'absolute',
	right: 'space.0',
	width: '100%',
	alignItems: 'center',
});

// CompanyHubGroupedButtons should stick to the top of the content area
const headerContainerStyles = xcss({
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100cqw',
	height: '48px',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	borderBottom: `1px solid ${token('color.border')}`,
	// @ts-ignore because none of the allowed contants fit the use case
	zIndex: 12, // Like FixedSpaceHeaderContainer used by SpaceOverviewGroupedButtons
	position: 'fixed',
	backgroundColor: 'elevation.surface',
});

const settingsButtonStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: 'border.radius.100',
});

export type CompanyHubGroupedButtonsProps = {
	contentId: string;
	spaceKey: string;
	spaceOverviewQueryResult: QueryResult<SpaceOverviewQueryType, SpaceOverviewQueryVariables>;
	versionOverride?: number | null;
};

export const CompanyHubGroupedButtons = ({
	contentId,
	spaceKey,
	spaceOverviewQueryResult,
	versionOverride,
}: CompanyHubGroupedButtonsProps) => {
	const canEdit = getCanEdit(spaceOverviewQueryResult);

	if (versionOverride != null) {
		return (
			<Box xcss={fixedPreviewPlaceholderStyles}>
				<HubPreviewHeader
					canEdit={canEdit}
					contentId={contentId}
					spaceKey={spaceKey}
					versionOverride={versionOverride}
				/>
			</Box>
		);
	}

	if (canEdit && !fg('company_hub_creation_ui')) {
		return (
			<Box>
				<Flex xcss={[headerContainerStylesOld]}>
					<Box xcss={settingsButtonStyles}>
						<HubSettingsEntryButton />
					</Box>
					<Box xcss={[editButtonStylesOld]}>
						<EditContentButton contentId={contentId} isCompanyHubButton />
					</Box>
					<DocumentSpaceTitle spaceKey={spaceKey} />
				</Flex>
			</Box>
		);
	}

	if (canEdit && fg('company_hub_creation_ui')) {
		return (
			<Box xcss={[fixedPlaceholderStyles]}>
				<Flex xcss={[headerContainerStyles]}>
					<HubStatusLozenge />
					<Inline space="space.100">
						<Inline xcss={[editButtonStyles]}>
							<EditContentButton contentId={contentId} isCompanyHubButton />
						</Inline>
						<HubSettingsEntryButton appearance={HubSettingsEntryButtonAppearance.ICON_ONLY} />
					</Inline>
				</Flex>
				<DocumentSpaceTitle spaceKey={spaceKey} />
			</Box>
		);
	}

	return null;
};

const getCanEdit = (
	spaceOverviewQueryResult: CompanyHubGroupedButtonsProps['spaceOverviewQueryResult'],
) =>
	Boolean(
		spaceOverviewQueryResult?.data?.space?.homepage?.operations?.some(
			(o) => o?.operation === 'update',
		),
	);

const HubPreviewHeader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-HubPreviewHeader" */ './HubPreviewHeader'))
			.HubPreviewHeader,
});
